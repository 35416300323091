import React from "react";
import './Services2.css';


//imgs 
import roomCleaning from '../../assets/imgs/room-cleaning.jpg';
import roomCleaningPng from '../../assets/imgs/room-cleaning-icon.png';
import windowClining from '../../assets/imgs/window-clining.jpg';
import windowCliningPng from '../../assets/imgs/window-cleaning-icon.png';
import glassCleaning from '../../assets/imgs/glass-cleaning.jpg';
import glassCleaningPng from '../../assets/imgs/glass-cleaning-icon.png';
import kitchen from '../../assets/imgs/kitchen-cleaning.jpg';
import kitchenPng from '../../assets/imgs/kitchen-cleaning-icon.png';
import toilet from '../../assets/imgs/toilet-cleaning.jpg';
import toiletPng from '../../assets/imgs/toilet-cleaning-icon.png';


const Services2 = () => {

    return (
        <div className="services2" id="services">
            <a
                href="sms:+7819909812"
                style={{ 'textDecoration': 'none', 'color': 'white' }}
            >
                <div className="contactFooter">

                    <div id="config">
                        <i className="fa fa-cog" aria-hidden="true"></i>

                    </div>

                    Our Services



                </div>
            </a>


            <div className="cards">

                <div className="cardContainer">
                    <div
                        className="card"
                        style={{ 'backgroundImage': `url(${roomCleaning})` }}
                    >
                        <div className="cardPng">
                            <img className="png" src={roomCleaningPng} alt="" />
                            <h3>
                                Standard  Cleaning
                            </h3>

                        </div>

                    </div>
                    <span>
                        Cleaning is not an easy thing, but with our great products, and with great care, we can make your house shine like never before.
                    </span>
                </div>

                <div className="cardContainer">
                    <div
                        className="card"
                        style={{ 'backgroundImage': `url(${windowClining})` }}
                    >
                        <div className="cardPng">
                            <img className="png" src={windowCliningPng} alt="" />
                            <h3>
                                Cleaning
                                Post-Work
                            </h3>
                        </div>

                    </div>
                    <span>
                    We give you a thorough cleaning of your property, whether residential or commercial. We clean with great care so it looks its best.
                    </span>
                </div>

                <div className="cardContainer">
                    <div
                        className="card"
                        style={{ 'backgroundImage': `url(${glassCleaning})` }}
                    >
                        <div className="cardPng">
                            <img className="png" src={glassCleaningPng} alt="" />
                            <h3>
                                FACILITIES
                            </h3>
                        </div>

                    </div>
                    <span>Our professionals work with the highest quality products and practices to get you the best results.</span>
                </div>

                
           {
            /**
             *      <div
                    className="card"
                    style={{ 'backgroundImage': `url(${kitchen})` }}
                >
                    <div className="cardPng">
                        <img className="png" src={kitchenPng} alt="" />
                        <h3>
                            Kitchen Cleaning
                        </h3>
                    </div>

                </div>

                <div
                    className="card"
                    style={{ 'backgroundImage': `url(${toilet})` }}
                >
                    <div className="cardPng">
                        <img className="png" src={toiletPng} alt="" />
                        <h3>
                            Toilet Cleaning
                        </h3>
                    </div>

                </div>
             */
           }


            </div>




        </div>
    )
}



export default Services2;