import React from 'react';
import './App.css';
import './assets/font-awesome/css/font-awesome.css'

import Routes from './rotes/Routes';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div className="App">
      <Routes />
      <Footer/>
    </div>
  );
}

export default App;
