import React from "react";
import './Footer.css';


const Footer = () => {

    return (
        <>
            <div className="footer" id='about'>
                <div className="contactFooterContainer">
                    <a
                        href="sms:+17819909812"
                        style={{ 'textDecoration': 'none', 'color': 'white' }}
                    >
                        <div className="contactFooter" >

                            <div id="config">

                                <i className="fa fa-comment" aria-hidden="true"></i>

                            </div>

                            Contact Us

                        </div>
                    </a>


                    <div className="footerPart2">

                        <h2>
                            Feel free to contact us
                        </h2>

                        <h3>
                            We are a large company with more than 10 years of experience in helping people with their house problems and malfunctions. During our work we helped a huge number of people and have garnered a reputation as a stable company with a team of real skilled experts who don’t fear any difficulties.
                        </h3>
                    </div>
                </div>

                <div
                    className="footerPart3"
                    id="footerPart3"
                >

                    <div
                        className="buttonsContatoFooter"
                    >

                        <a
                            href="sms:+17819909812"
                            style={{ 'textDecoration': 'none', 'color': 'white' }}
                        >
                            <i className="fa fa-phone"></i>
                        </a>

                        <div
                            className="numberContainer"
                            onClick={e => {

                                window.location.href = "sms:+17819909812"
                            }}
                        >
                            <h3>Phone</h3>
                            <span>(781) 990-9812 </span>
                        </div>

                    </div>

                    <div
                        className="buttonsContatoFooter"
                    >

                        <a
                            href="https://www.instagram.com/perfectcleaninggc/"
                            style={{ 'textDecoration': 'none', 'color': 'white' }}
                        >
                            <i className="fa fa-instagram"></i>
                        </a>

                        <div
                            className="numberContainer"
                        >
                            <h3>Instagram </h3>
                            <span>@perfectcleaninggc</span>
                        </div>

                    </div>

                    <div
                        className="buttonsContatoFooter"
                    >

                        <a
                            href="mailto:Scleisianagomes@gmail.com "
                            style={{ 'textDecoration': 'none', 'color': 'white' }}
                        >
                            <i className="fa fa-envelope"></i>
                        </a>


                        <div
                            className="numberContainer"
                        >
                            <h3>Email</h3>
                            <span> Scleisianagomes@gmail.com  </span>
                        </div>

                    </div>

                    <div
                        className="buttonsContatoFooter"
                    >

                        <a
                            href="sms:+17819909812"
                            style={{ 'textDecoration': 'none', 'color': 'white' }}
                        >
                            <i className="fa fa-map-marker"></i>
                        </a>

                        <div
                            className="numberContainer"
                        >
                            <h3>Location</h3>
                            <span>Rhode Island </span>
                        </div>

                    </div>



                    <div
                        className="buttonsContatoFooter"
                    >

                        <a
                            href="sms:+17819909812"
                            style={{ 'textDecoration': 'none', 'color': 'white' }}
                        >
                            <i className="fa fa-clock-o" aria-hidden="true"></i>

                        </a>

                        <div
                            className="numberContainer"
                        >
                            <h3>Open</h3>
                            <span>8:00 AM - 5:00 PM (Mon-Sat)</span>
                        </div>

                    </div>

                </div>



            </div>
            <div className="reservas">
                <h3>
                    © 2022 Copyright Reserved
                </h3>
                <div className="linhaVerticalFooter">

                </div>
                <div>
                    <h3 className="linkVitor">
                        Designed by  <a href=""> {` Vítor Henrique`}</a>
                    </h3>
                </div>

            </div>
        </>

    )
}


export default Footer;