import React from "react";
import { useState } from "react";
import './MenuMobile.css';


const MenuMobile = (props) => {

    const [state, setState] = useState({
        display: false,
        menuPort: true
    })

    if (props.display != state.display) {
        setState({
            ...state,
            display: props.display
        })
    }

    function Menus() {


        return (
            <div className="menusMobile">

                <div
                    className={state.foco === 'HOME' ? "buttons focus" : "buttons"}
                    id="homeMobile"
                    value='HOME'

                >
                    <section className="clock">

                        <i className="fa fa-clock-o" aria-hidden="true"></i>
                        <span>  8:00 AM - 5:00 PM (Mon-Sat)</span>

                    </section>

                    <a
                        href="sms:+17819909812"
                        style={{ 'textDecoration': 'none', 'color': 'white' }}
                    >
                        <section
                            className="clock"

                        >
                            <i className="fa fa-phone" aria-hidden="true"></i>

                            <span>
                                (781) 9909812
                            </span>
                        </section>
                    </a>
                </div>


                <a href="#services" id="Services">
                    <div
                        className={state.foco === 'SERVICES' ? "buttons focus" : "buttons"}
                        value='SERVICES'
                        onClick={e => {
                            setFocus(e)
                        }}
                    >
                        Services
                    </div>
                </a>

                <a href="#about" id="Services">
                    <div
                        className={state.foco === 'ABOUT' ? "buttons focus" : "buttons"}
                        value='ABOUT'
                        onClick={e => {
                            setFocus(e)
                        }}
                    >
                        About Us
                    </div>
                </a>

                {/**
              *    <div
                    className={state.foco === 'PRICING' ? "buttons focus" : "buttons"}
                    value='PRICING'
                    onClick={e => {
                        setFocus(e)
                    }}
                >
                    Pricing Plans
                </div>
              */}
                <a href="#footerPart3" id="Services">

                    <div
                        className={state.foco === 'CONTACT' ? "buttons focus" : "buttons"}
                        value='CONTACT'
                        onClick={e => {
                            setFocus(e)
                        }}
                    >
                        Contact US
                    </div>
                </a>

              {/**
               *   <a>
                    <div
                        className="showStatus"
                        onClick={e => {
                            alert(
                                window.innerWidth,
                                window.innerHeight
                            )
                        }}
                    >
                        onClick
                    </div>
                </a>
               */}
            </div>
        )
    }

    function setFocus(value) {

        console.log(value.target.attributes.value.value)

        setState({
            ...state,
            foco: value.target.attributes.value.value
        })
        //      <a href="#services"> Services</a>

    }


    return (

        <div className="cotainerMenuMobile">
            <div className={state.display ? 'menuMobile' : 'menuMobile show'} >
                <Menus />
            </div>
        </div>
    )
}

export default MenuMobile;